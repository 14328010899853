<template>
	<div
		id="home"
		:style="{
			paddingTop: this.$vuetify.breakpoint.smAndDown ? this.appBarSizes.mobileHeight : this.appBarSizes.desktopHeight,
		}"
	>
		<v-layout wrap fill-height>
			<v-flex xs12 sm12 md12>
				<carousel
					:images="getSite.banners"
					:options="{ dots: true, arrows: false, autoPlaySlideInterval: 5000, showIndicatorOutside: true }"
				/>
				<!-- <slider :imageData="getSite.banners" :options="{ dots: true, arrows: false }" v-if="false" /> -->
			</v-flex>
		</v-layout>
		<div fluid v-if="getSite">
			<Model />
		</div>
	</div>
</template>

<script>
import Model from '@/components/enduser/dark-one/Model.vue';
import { mapGetters } from 'vuex';
// import Slider from '../../../../components/_shared/Slider.vue';
import Carousel from '../../../../components/_shared/Carousel.vue';

export default {
	name: 'Home',
	components: {
		Model,
		// Slider,
		Carousel,
	},
	data() {
		return {
			carousel: 0,
		};
	},
	computed: {
		...mapGetters(['getSite']),
		isDelimiterHide() {
			return this.getSite.banners.length <= 1;
		},
		computedHeight() {
			let height = '65vh';

			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					height = '30vh';
					break;
				case 'sm':
					height = '35vh';
					break;
				case 'md':
					height = '45vh';
					break;
				case 'lg':
					height = '50vh';
					break;
				case 'xl':
					height = '65vh';
					break;
				default:
			}

			return height;
		},
		isTopBarShow() {
			return this.getSite?.showContactInfoHeader;
		},
		appBarSizes() {
			return this.isTopBarShow
				? {
						mobileHeight: '110px',
						desktopHeight: '120px',
						maxHeight: '120px',
				  }
				: {
						mobileHeight: '85px',
						desktopHeight: '95px',
						maxHeight: '100px',
				  };
		},
	},
	methods: {
		bannerTextContainerIsShowing(banner) {
			if (banner.title || banner.subTitle) {
				return true;
			}
			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
#home {
	.banner-text-bg {
		background-color: rgba(41, 41, 41, 0.2);
		backdrop-filter: blur(5px);
		border-radius: 5px;
		overflow: hidden;
	}

	padding-bottom: 0px;
	background-color: #f1f1f1;
	position: relative;
}
</style>
