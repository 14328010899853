<template>
	<div id="why-us">
		<v-layout wrap justify-center align-center class="text-center py-10">
			<v-flex xs12 sm12 md12 class="text-h4 font-weight-bold lg-header-link-1--text">{{
				getSite.processHeader
			}}</v-flex>
			<v-flex xs12 sm12 md5 class="text-subtitle-1 mt-5 px-5">{{ getSite.processDescription }}</v-flex>

			<v-flex md12>
				<template v-for="(whyUs, index) in whyUsContents">
					<v-flex
						class="why-use-row d-flex align-center justify-center"
						:key="`whyUs-${index}`"
						:class="index % 2 === 0 ? 'white-to-grey' : 'grey-to-white'"
					>
						<v-flex md5 class="d-flex justify-space-between align-center text-left fill-height">
							<v-flex md-6 v-if="index % 2 === 0">
								<v-img
									contain
									max-width="150"
									max-height="120"
									width="155"
									height="120"
									:src="whyUs.icon"
									:class="$vuetify.breakpoint.mdAndDown ? 'mr-1' : 'mr-16'"
								></v-img>
							</v-flex>
							<v-flex md6 :class="index % 2 === 0 ? 'text-left' : 'text-right'">
								<div
									:class="$vuetify.breakpoint.mdAndDown ? 'text-subtitle-1' : 'text-h5 mb-2'"
									class="lg-header-link-1--text text--lighten-1 font-weight-bold px-1"
								>
									{{ whyUs.title }}
								</div>
								<div class="px-1" :class="{ spacing: $vuetify.breakpoint.mdAndDown }">{{ whyUs.desc }}</div>
							</v-flex>
							<v-flex md6 v-if="index % 2 !== 0" class="d-flex justify-end">
								<v-img
									contain
									max-width="150"
									max-height="120"
									width="155"
									height="120"
									:src="whyUs.icon"
									:class="$vuetify.breakpoint.mdAndDown ? 'mr-1' : 'mr-16'"
								></v-img>
							</v-flex>
						</v-flex>
					</v-flex>
				</template>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'WhyUse',
	data() {
		return {};
	},
	computed: {
		...mapGetters(['getSite', 'darkTemplate']),
		whyUsContents() {
			return this.getSite?.businessStep;
		},
	},
};
</script>

<style lang="scss" scoped>
.why-use-row {
	height: 168px;
	margin: 25px 0px 30px 0px;

	.spacing {
		line-height: 16px;
	}

	&:last-child {
		margin-bottom: 0px;
	}

	&.grey-to-white {
		background: rgb(244, 244, 244);
		background: linear-gradient(90deg, rgba(244, 244, 244, 1) 0%, rgba(255, 255, 255, 1) 100%);
	}
	&.white-to-grey {
		background: rgb(255, 255, 255);
		background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(244, 244, 244, 1) 100%);
	}
}
</style>
