<template>
	<div class="container-fluid" :class="templateClass" v-if="siteReady" :style="style">
		<component v-bind:is="currentTheme" />
	</div>
</template>

<script>
import { getContrast50 } from '@/common/utils';
import templateMixin from '@/mixins/templateMixin';
import { INIT_COMPANY } from '@/store/modules/company.module';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'Main',
	mixins: [templateMixin],
	data() {
		return {
			initialized: false,
		};
	},
	created() {
		this.$nextTick(() => {
			this.initSite();

			setTimeout(() => {
				const elementId = this.$route?.name;

				const element = document.getElementById(elementId);
				if (element) this.$vuetify.goTo(element, { offset: 0 });
			}, 500);
		});
	},
	computed: {
		...mapGetters([
			'getSite',
			'templateHexColor',
			'templateTextHexColor',
			'darkTemplate',
			'buttonBackColor',
			'buttonTextColor',
			'headerBackColor',
			'headerTextColor',
			'footerBackColor',
			'footerTextColor',
		]),
		style() {
			return {
				'--v-contrast-color': getContrast50(this.templateHexColor.replace('#', '')),
			};
		},
		currentTheme() {
			return this.getSite.templateCode;
		},
		templateClass() {
			return this.getSite.templateCode;
		},
		siteReady() {
			return this.getSite && this.initialized;
		},
	},
	methods: {
		...mapActions({
			initCompany: INIT_COMPANY,
		}),

		initSite() {
			if (this.getSite.isRedirect) {
				window.location.href = this.getSite.redirectUrl;
				return;
			}
			this.initialized = true;
		},
	},
};
</script>
<style lang="scss">
.v-application {
	background-color: var(--v-background-base) !important;
	color: var(--v-lg-primary-text) !important;

	.error {
		&--text {
			color: var(--v-contrast-color) !important;
		}
	}
	.contrast-color {
		color: var(--v-contrast-color) !important;
	}
}

.v-subheader {
	padding: 0px 10px;
	height: 30px;
}

.dark-one {
	.v-subheader {
		color: var(--v-template-text-base) !important;
	}
}
.light-one {
	.v-subheader {
		color: rgb(134, 134, 134) !important;
	}
}

.blue-one {
	.wizard-card {
		.v-card__text {
			background-color: var(--v-lg-damage-bg-base);
		}

		.damage-part {
			color: #a0a0a0;
		}
		.issue-type {
			color: #a0a0a0 !important;
		}
		.legenda-text {
			color: #a0a0a0;
		}
	}
}
.dark-one {
	.wizard-card {
		.v-card__text {
			background: var(--v-template-color-base);
			background: linear-gradient(180deg, var(--v-template-color-lighten1) 0%, var(--v-template-color-base) 100%);
		}

		.damage-part {
			color: var(--v-template-text-base);
		}
		.issue-type {
			color: var(--v-template-text-base) !important;
		}
		.legenda-text {
			color: var(--v-template-text-base);
		}
	}
}
</style>
