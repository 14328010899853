<template>
	<div id="faq" v-if="getSite">
		<v-layout wrap justify-center align-center class="text-center pa-5">
			<v-flex xs12 sm12 md12 class="text-h4 font-weight-bold lg-header-link-1--text">{{ getSite.faqHeader }}</v-flex>
			<v-flex xs12 sm12 md5 class="text-subtitle-1 py-5">
				{{ getSite.faqDescription }}
			</v-flex>
			<v-flex md12 class="d-flex align-center justify-center text-left py-5">
				<v-flex md5>
					<v-expansion-panels light multiple flat accordion>
						<v-expansion-panel v-for="(item, index) in getSite.faq" :key="`faq-${index}`">
							<v-expansion-panel-header>
								<span class="font-weight-bold text-subtitle-1">{{ item.question }}</span>
								<template v-slot:actions>
									<v-icon v-text="''"> </v-icon>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								{{ item.answer }}
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-flex>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Faq',
	data() {
		return {};
	},
	computed: {
		...mapGetters(['getSite']),
	},
};
</script>

<style lang="scss" scoped>
#faq {
	.v-expansion-panels {
		border: 1px solid #e3e3e3;
		border-radius: 10px;

		&:last-child {
			border-bottom: 0px;
		}

		.v-expansion-panel {
			border-bottom: 1px solid #e3e3e3;
			.v-expansion-panel-header {
				.v-icon {
					&::before {
						font-family: 'Font Awesome 5 Free';
						font-weight: 600;
						font-style: normal;
						font-size: 16px;
						content: '\f067';
					}
				}

				&--active {
					.v-icon {
						&::before {
							font-family: 'Font Awesome 5 Free';
							font-weight: 600;
							font-style: normal;
							font-size: 16px;
							content: '\f068';
						}
					}
				}
			}
		}
	}
}
</style>
