<template>
	<div id="contact" v-if="getSite">
		<v-row no-gutters class="text-center">
			<v-flex
				v-if="getWizardStep === wizardStep.MODEL"
				md12
				class="text-h4 font-weight-bold py-5 lg-header-link-1--text"
				>İletişim</v-flex
			>
			<v-flex xs12 sm12 md5 class="py-5 d-flex justify-end px-5 contact-bg">
				<v-flex xs12 sm12 md12 lg8 class="text-left">
					<div class="text-h5">
						Bizimle iletişime geçin!
						<div class="text-subtitle-1 my-4 pr-5">
							{{ getSite.address }} / {{ getSite.addressCity }}
							<div v-if="getSite.showContactInfoHeader">
								<div v-if="getSite.phone != null && getSite.phone != ''">
									<a :href="`tel:${getSite.phone}`" class="contact-info">
										{{ getSite.phone | VMask(phoneMask) }}
									</a>
								</div>
								<div v-if="getSite.email != null && getSite.email != ''">
									<a :href="`mailto:${getSite.email}`" class="contact-info">
										{{ getSite.email }}
									</a>
								</div>
							</div>
						</div>
						<div class="contact-form" :class="{ ' pr-15': $vuetify.breakpoint.mdAndUp }">
							<v-row>
								<v-col cols="12">
									<v-text-field
										dark
										color="template-text"
										hide-details="auto"
										label="Adınız ve Soyadınız"
										v-model="$v.contact.name.$model"
										:error-messages="nameErrors()"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										dark
										color="template-text"
										hide-details="auto"
										label="E-Mail adresiniz"
										v-model="$v.contact.eMail.$model"
										:error-messages="emailErrors()"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field-simplemask
										v-model="contact.phone"
										v-bind:label="'Telefon numaranız'"
										v-bind:properties="{
											inputmode: 'numeric',
											dark: true,
											hideDetails: 'auto',
											placeholder: '0(555) 555-5555',
											color: 'template-text',
										}"
										v-bind:options="{
											inputMask: '#(###) ###-##-##',
											outputMask: '###########',
											empty: null,
											applyAfter: false,
											alphanumeric: false,
										}"
									>
									</v-text-field-simplemask>
								</v-col>
								<v-col cols="12">
									<v-text-field
										counter="500"
										dark
										color="template-text"
										hide-details="auto"
										label="Mesaj"
										v-model="contact.message"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										color="template-text--text"
										:dark="darkTemplate"
										dense
										hide-details="auto"
										:error-messages="kvkkApprovedErrors()"
										v-model="$v.contact.kvkkApproved.$model"
									>
										<template v-slot:label>
											<div class="caption">
												<span
													class="caption font-weight-bold"
													:class="darkTemplate ? 'template-text--text' : ' template-color--text'"
												>
													{{ getSite.companyName }}
													<a
														:class="darkTemplate ? 'template-text--text' : ' template-color--text'"
														:href="getSite.endUserContractFile"
														target="_blank"
														@click.stop
														>KVKK Aydınlatma Metnini</a
													>
													okuyup anladığımı, verilerimin işlenmesi, aktarılması ve kullanılmasına bu çerçevede açıkça
													rıza gösterdiğimi beyan ve kabul ederim.
												</span>
											</div>
										</template>
									</v-checkbox>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="text-left">
									<vue-recaptcha
										ref="recaptcha"
										@verify="onVerifyCaptcha"
										@expired="onExpiredCaptcha"
										:sitekey="captchaSiteKey"
										:loadRecaptchaScript="true"
									>
									</vue-recaptcha>
									<div class="error--text" v-if="$v.captchaValid.$error">
										<small>Lütfen robot olmadığınızı doğrulayın!</small>
									</div>
								</v-col>
								<v-col class="text-right">
									<v-btn
										dark
										color="button-color lighten-1 button-text--text"
										class="px-5 text-capitalize"
										:disabled="isSendDisabled"
										:loading="loading"
										:class="contactButtonClass"
										@click="sendContactForm"
										>Gönder</v-btn
									>
								</v-col>
							</v-row>
						</div>
					</div>
				</v-flex>
			</v-flex>
			<v-flex xs12 sm12 md7>
				<div class="gmap_canvas" :class="{ 'map-mobile': $vuetify.breakpoint.smAndDown }">
					<iframe
						width="100%"
						height="100%"
						id="gmap_canvas"
						:src="mapSource"
						frameborder="0"
						scrolling="no"
						marginheight="0"
						marginwidth="0"
					></iframe>
				</div>
			</v-flex>
		</v-row>
	</div>
</template>

<script>
import ApiService from '@/common/api.service';
import { wizardStep } from '@/common/enums';
import contactFormValidationMixin from '@/mixins/validations/company/contactFormValidationMixin';
import globalValidationErrorMixin from '@/mixins/validations/globalValidationErrorMixin';
import { SET_MESSAGE_DIALOG } from '@/store/modules/messageDialog.module';
import VueRecaptcha from 'vue-recaptcha';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';

const initialContactModel = () => ({
	name: null,
	eMail: null,
	phone: null,
	message: null,
	kvkkApproved: null,
});

export default {
	name: 'ContactView',
	mixins: [validationMixin, globalValidationErrorMixin, contactFormValidationMixin],
	components: {
		VueRecaptcha,
	},
	data() {
		return {
			endPoints: {
				sendContactForm: 'company/sendcontactform',
			},
			contact: initialContactModel(),
			wizardStep,
			loading: false,
			captchaValid: false,
		};
	},
	computed: {
		...mapGetters(['getSite', 'darkTemplate', 'getWizardStep']),
		mapSource() {
			return `https://maps.google.com/maps?q=${this.getSite.mapLatitude},${this.getSite.mapLongitude}&hl=tr&z=18&output=embed`;
		},
		contactButtonClass() {
			return this.darkTemplate ? [] : ['rounded-lg'];
		},
		captchaSiteKey() {
			return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
		},
		isSendDisabled() {
			return this.$v.$invalid;
		},
		phoneMask() {
			if (this.getSite?.phone.startsWith('444')) return '### # ###';

			return '0 (###) ### ## ##';
		},
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
		}),
		onExpiredCaptcha() {
			this.captchaValid = false;
			this.$refs.recaptcha.reset();
		},
		onVerifyCaptcha(recaptchaToken) {
			this.contact.token = recaptchaToken;
			this.captchaValid = true;
		},
		sendContactForm() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			this.loading = true;
			ApiService.post(this.endPoints.sendContactForm, this.contact)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						title: 'Mesaj gönderildi!',
						text: 'Mesaj başarılı bir şekilde gönderilmiştir.',
					});
					this.contact = initialContactModel();
					this.$v.$reset();
				})
				.catch(() => {
					this.setMessageDialog({
						messageType: 'error',
						title: 'Mesaj gönderilemedi!',
						text: 'Mesajınız gönderilirken bir hata oluştu lütfen daha sonra tekrar deneyiniz.',
					});
				})
				.finally(() => {
					this.loading = false;
					this.captchaValid = false;
					this.$refs.recaptcha.reset();
				});
		},
	},
};
</script>

<style lang="scss" scoped>
#contact {
	.contact-form {
		::v-deep {
			.v-input {
				color: var(--v-contrast-color) !important;
				&__control {
					.v-input__slot {
						&:before {
							border-color: var(--v-contrast-color) !important;
						}
					}

					::placeholder {
						color: var(--v-contrast-color) !important;
					}

					color: var(--v-contrast-color) !important;
					label {
						color: var(--v-contrast-color) !important;
					}
					input {
						color: var(--v-contrast-color) !important;
					}
					.v-counter {
						color: var(--v-contrast-color) !important;
					}
				}
			}
			.v-btn {
				&--disabled {
					color: var(--v-contrast-color) !important;
				}
			}
		}
	}

	.content {
		height: 532px;
		max-height: 532px;
		overflow: hidden;
	}

	.gmap_canvas {
		position: relative;
		height: 100%;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}

	.map-mobile {
		width: 100%;
		min-height: 300px;
	}

	.contact-bg {
		color: var(--v-template-text-base);
		background-color: var(--v-template-color-darken1);
	}

	.contact-info {
		color: var(--v-template-text-base) !important;
		text-decoration: none;
	}
}
</style>
